import React from 'react';
import {classes} from './Summary.st.css';
import {SecureCheckout} from '../../../common/components/SecureCheckout/SecureCheckout';
import {PaymentRequestTotals} from './PaymentRequestTotals/PaymentRequestTotals';
import {PaymentRequestItemSection} from './PaymentRequestItemSection/PaymentRequestItemSection';
import {useControllerProps} from '../Widget/ControllerContext';
import {PaymentRequestMobileSummary} from './PaymentRequestMobileSummary';

export enum SummaryDataHooks {
  couponSectionInput = 'SummaryDataHooks.couponSectionInput',
  giftCardSectionInput = 'SummaryDataHooks.giftCardSectionInput',
  violations = 'SummaryDataHooks.violations',
}

export interface SummaryProps {
  dataHook: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Summary = ({dataHook}: SummaryProps) => {
  const {isOneColumnView} = useControllerProps();

  return (
    <div className={isOneColumnView ? classes.sticky : classes.root} data-hook={dataHook}>
      {isOneColumnView ? (
        <PaymentRequestMobileSummary />
      ) : (
        <>
          <div className={classes.summary}>
            <PaymentRequestItemSection />
            <PaymentRequestTotals total="$100.00" />
          </div>
          <SecureCheckout />
        </>
      )}
    </div>
  );
};
